@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex-shrink: 2;
  flex-grow: 1;
  font-family: 'MyWebFont';
  height: 500vh;
  background-color: #eaeaea;
  /* width: 700px; */
  overflow-x: hidden;
 

}

.mainContainer{

  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around !important;
  /* box-sizing: border-box; */
  /* flex-shrink: 2; */
  /* height: 500vh; */
  /* text-align: left; */
  /* padding-left: 32px; */
  /* margin-left: 16px; */
  width: 100vw;
}

.headerContainer{
  display: flex;
  flex-direction: row;
 
  justify-content: space-around;
 
  padding: 16px 16px 16px 0px;
  background-image: linear-gradient(#0081A7 , #00afb9);
  width: 100vw;
  align-items: center;

}

.flexHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1%;

  width: 70%;
}


.my-next-role-input{
  height: 10px;
}

#inputandsave{
  display:flex;
  flex-direction: row;

}

#jobTitleDisplayed {
  /* display: flex;
  flex-direction: row; */
  color:teal;
  font-size: 1.0rem;
  font-weight: bold;
  margin-left: 150%;
  width: 20vw;
  margin-top: 12%;
  background-color: #dd6e42;

 

}

.App, .mainContainer{
  width: 100%;
}



.myRoleButton{
  font-size: 0.55rem;
  padding: 1%;
}

.endDatebtn, .startDatebtn, .myRoleButton, .submitButton {
 margin-left: 2px;
}

.editXbtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
}
.editbtn {
  margin-left: 4px;
}

.editXbtn, .editbtn {
  opacity: .75;
  font-size: 8px;
  
}



.accompListEditXbtns {
  /* flex-wrap: wrap; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  
}

.accomp-list-items{
  /* margin-top: 5%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: .60rem;

  padding: 1%;


 
}

/* .accomp-box{
  display: flex;
  flex-direction: column;


} */

.listBox{
  margin-top: 5%;
}

.goalsInput, .AccompInput {
  width: 300px;
  

}

.sdinput, .edinput{
  width: 100px;
}


.jlheader, .goalsHeader, .accompHeader{
font-size: 1.0rem;
text-align: center;
color: teal;
font-weight: bold;
}

#roleHeader{
  color: white;
  font-weight: bold;
  font-size: 1.0rem;
  width: 25%;
}

#jobTitleInput{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
  height: 5%;
  background-color: #eaeaea;

}

.jobListingContainer, .AccompContainer, .goalsContainer {
  margin-top: 20px;
  box-shadow: 4px 4px 4px #dd6e42;
  overflow: auto;
  font-size: 0.75rem;
  flex-shrink: 2;
  margin-left: 5%;
}

.jobListingContainer{
width: 400px;
/* height: 1200px; */
height: 100vh;
padding: 40px;
background-color: #00afb9;

}

.goalsInput, .AccompInput, .sdinput, .edinput{
 justify-content: center;
 background-color: #eaeaea;
 border: white;
 height: 4vh;
 font-size: 0.7rem;
}

.jobContent{
  font-size: .60rem;
}
/* li, .AccompList{
  list-style: circle;
} */


.goalsContainer, .AccompContainer {
  background: #00afb9;
  width: 400px;
  margin-left: 32px;
  /* padding: 20px; */
  text-align: left;
  margin-top: 20px;
  padding: 40px;
  height: 40vh;
}

.AccompContainer {
  margin-top: 20px;
  height: 40vh;
  }

 /* .goalsContainer {
  height: 400px; 
  height: 50vh;
}  */

.goalsAndAccompContainer{
  flex-direction: column;
}

.descrip{
font-weight: bold;
font-size: .70rem;
}

.App {
  width: 100vw;
}

.accomp-list-items{
  font-size: 0.7rem;
  color: teal;
  list-style: none;

}

.date{
  font-size: 0.5rem;
  display: flex;
  justify-content: flex-start;
  margin-right: 10px;
}

.goalContainer{
  /* border: 2px gray outset; */
  padding: 6%;
  margin-top: 4%;
  width: 130%;
  display: flex;
  flex-direction: column;
  justify-content: center;
 


}

.commentBox{
  display: flex;
  justify-content: flex-start;
  margin-top: 6%;

}

.commentList{

  font-size: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;


}

.goal-page-container{
  display: flex;
  flex-direction: row;


}

.display-Goals{
  height: 100vh;
}

.prev-next-buttons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.my-role-box{

   display: flex;
  flex-direction: row; 
  justify-content: flex-start; 
  height: 100%;
  margin-bottom: 2%;
  margin-top: 2%;
  width: 75%;

 
}

.my-role-flex-box{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: 60%;
}

.my-next-role{
  color: teal;
  /* margin-left: 10%; */
  font-size: 0.7rem;

padding-right: 10px;
}

/*
Color Palette:

HEX 22223B

HEX #0081A7 - blue
HEX #00afb9 - teal
HEX #fdfcdc - creme

HEX CB997E - darker tan
HEX CB997E lighter tan
HEX FFF1E6 - cream
HEX F0EFEB - light grayish
HEX DDBEA9 - bandaid color
HEX A5A58D -darker olive green
HEX B7B7A4 - lighter olive green



*/
