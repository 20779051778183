.login-input{
    font-size: .7rem;
}

#password-helper-text, #email-helper-text{
    font-size: 0.5rem;

}

.form-labels{
    font-size: .70rem;
}




.about-list{
    font-size: 1.0rem;
    border: 1px solid teal;
    border-radius: 4px;
    padding: 2%;
    margin:2%;
}